.content-review-list {
	padding: 3rem 0;
}

.review-list {
	&__lead {
		margin-top:2rem;
		margin-bottom: 3rem;
	}
}
@media (min-width: 768px) {
	.review-list {
		&__lead {
			margin-top:0;
		}
	}
}

.review-item {
	display: block;
	padding: 1.5rem 0;
	color: $body-text-color;
	text-decoration: none;

	&:hover {
		color: $body-text-color;
	}

	&__title {
		margin-bottom: 0.875rem;
		font-size: 1.25rem;
	}
	&__author {
		margin-bottom: 0.875rem;
		color: $brand-blue;
	}
	&__text {
		font-size: 0.9375rem;
	}
}

@media(min-width: 768px) {
	.review-item {
		&:hover {
			background-color: $link-hover-bg;
		}
	}
}
