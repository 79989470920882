
.mg-wp-zeno-content-protected {
	position: relative;

	&::after {
		content: '';
		background-position: center center;
		background-size: cover;
		height: 100%;
		margin: 0px;
		width: 100vw;
		position: absolute;
		left: calc(-1 * (100vw - 100%) / 2);
		z-index: 0;
		bottom: 0px;
		background: linear-gradient(to bottom, rgba(254, 249, 245, 0) 0%, rgba(254, 249, 245, 1) 100%);
	}
}

.mg-wp-zeno-login-wall {
	display: flex;
	flex-flow: column;
	width: 100vw;
	position: relative;
	background-color: #fff;
	left: calc(-1 * (100vw - 100%) / 2);
	color: rgba(0, 0, 0, .6);
	padding: 10rem 1rem 5rem 1rem;
	text-align: center;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);

	.mg-wp-zeno-login-wall-title {
		margin: 0 auto 1.5rem auto;
		max-width: 720px;
		font-size: 1.25rem;
		color: rgb(243, 115, 71);
	}

	.mg-wp-zeno-login-wall-text {
		margin: 0 auto;
		max-width: 720px;
		font-size: 1rem;
	}
	.mg-wp-zeno-login-wall-button {
		&.mg-wp-zeno-login-wall-button--even {
			margin: 1.5rem auto 1.5rem auto;
			padding: 0.375rem 1.5rem;
			background-color: $brand-orange;
			color: #ffffff;
			font-size: 1rem;
			font-weight: 400;
			text-decoration: none;
			display: inline-block;
			order: 1;
			&:hover {
				background-color: #f58863;
			}
		}
		&.mg-wp-zeno-login-wall-button--uneven {
			font-size: 1rem;
			font-weight: 400;
			text-decoration: underline;
			order: 2;
		}
	}

	.brand-arrow {
		position: absolute;
		height: auto;

		.brand-arrow__text {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-weight: 500;
			color: $pure-white;

		}

		&--right {
			top: 8rem;
			left: 0;
			width: 10rem;

			.brand-arrow__text {
				padding-right: 20%;
				font-size: 1.125rem;
			}
		}

		&--down {
			top: 0;
			left: 0;
			right: 0;
			width: 6.25rem;
			margin-left: auto;
			margin-right: auto;

			.brand-arrow__text {
				padding-bottom: 20%;
				font-size: 1rem;
			}
		}
	}

}

@media(min-width: 992px) {
	.mg-wp-zeno-login-wall {
		padding-top: 5rem;
	}
}
